<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item style="margin-bottom: 0;">
			<el-date-picker v-model="searchParams.legal" placeholder="检查时间" />
				</el-form-item>-->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button
          size="mini"
          type="primary"
          @click="handleAction(scope.row, 'check')"
          >检查</el-button
        >
      </template>
    </default-table>
    <el-dialog
      :visible.sync="visible"
      width="630px"
      custom-class="dialog"
      title="提交"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <ele-form
        v-model="formData"
        :form-desc="formDesc"
        labelPosition="right"
        :span="24"
        :isShowSubmitBtn="false"
        :isShowBackBtn="false"
      >
        <div style="text-align: center" slot="form-footer">
          <el-button type="primary" size="mini" @click="handleSubmit"
            >提交</el-button
          >
          <el-button type="info" plain size="mini" @click="handleCancel"
            >取消</el-button
          >
        </div>
      </ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
// import List from '@/components/List';
import { queryRoutineList, exportRoutine, saveRoutine } from '@/api/routine';
import moment from 'moment';
import EleForm from 'vue-ele-form';
import mixins from '@/mixins';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable,
    EleForm
    // List
  },
  data() {
    return {
      visible: false,
      detailInfo: [],
      formData: {
        tanker_id: '',
        last_time: moment().format('yyyy-MM-DD'),
        last_note: '',
        num: '',
        expire_date: '',
        measure_exam_org: ''
      },
      searchParams: {
        site_id: ''
        // legal: ''
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: true,
          width: 64,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          fixed: true,
          width: 220
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          width: 100
        },
        // {
        //   title: '生产厂家',
        //   key: 'manufacturer',
        //   width: 280
        // },
        {
          title: '加油机出厂编号',
          key: 'factory_num',
          width: 140
        },
        {
          title: '出厂日期',
          key: 'start_date',
          width: 100
        },
        {
          title: '器具编号',
          key: 'num',
          width: 120
        },
        {
          title: '检定有效期',
          key: 'expire_date',
          width: 100
        },
        {
          title: '检定机构',
          key: 'measure_exam_org',
          width: 220
        },
        {
          title: '上次检查时间',
          key: 'last_time',
          width: 120
        },
        {
          title: '下次检查时间',
          key: 'this_time',
          extraType: 'time',
          width: 120
        },
        // {
        //   title: '状态',
        //   key: 'status',
        //   width: 90
        // },
        {
          title: '上次检查备注',
          key: 'last_note',
          // fixed: 'right',
          width: 200
        },
        {
          title: '操作',
          fixed: 'right',
          key: 'actions',
          width: 80,
          // extra: [{ label: '检查', name: 'check' }],
          scopedSlots: { customRender: 'actions' }
        }
      ],
      formDesc: {},
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      pickerOptions: {
        disableDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      }
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleAction(e, t) {
      switch (t) {
        case 'delete':
          this.handleDelete(e);
          break;
        default:
          this.handleEdit(e);
          break;
      }
    },
    handleClose() {
      this.handleCancel();
    },
    handleCancel() {
      this.visible = false;
      this.formDesc = {};
    },
    handleDelete(e) {
      console.log('delete', e);
    },
    handleEdit(e) {
      this.visible = !this.visible;
      this.formDesc = {
        site_name: {
          type: 'input',
          label: '加油站名称',
          disabled: true,
          default: e.site_name
        },
        tanker_name: {
          type: 'input',
          label: '加油机名称',
          disabled: true,
          default: e.tanker_name
        },
        num: {
          type: 'input',
          label: '器具编号',
          default: e.num
        },
        expire_date: {
          type: 'date',
          label: '检定有效期',
          attrs: {
            valueFormat: 'yyyy-MM-dd'
          }
        },
        measure_exam_org: {
          type: 'input',
          label: '鉴定机构',
          default: e.measure_exam_org
        },
        last_time: {
          type: 'date',
          label: '检查时间'
        },
        last_note: {
          type: 'textarea',
          label: '检查备注'
        }
      };
      this.formData = {
        tanker_id: e.id,
        last_time: moment().format('yyyy-MM-DD'),
        last_note: e.last_note,
        num: e.num,
        expire_date: '',
        measure_exam_org: e.measure_exam_org
      };
    },
    handleSubmit() {
      const data = {
        tanker_id: this.formData.tanker_id,
        last_time: this.formData.last_time,
        last_note: this.formData.last_note,
        num: this.formData.num,
        measure_exam_org: this.formData.measure_exam_org,
        expire_date: moment(this.formData.expire_date).format('yyyy-MM-DD')
      };
      //   console.log(data);
      saveRoutine(data).then(res => {
        const { code, msg } = res;
        const msgConf =
          code === 0
            ? {
                message: '提交成功',
                type: 'success'
              }
            : {
                message: msg,
                type: 'error'
              };
        this.visible = false;
        this.$message(msgConf);
        this.handleQuery({
          current: 1,
          pageSize: 10
        });
      });
    },
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      queryRoutineList(params).then(res => {
        // console.log(res);
        this.data = [];
        if (res.code === 0) {
          res.data.map(item => {
            this.data.push({
              ...item,
              status: '待检查'
            });
          });
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      exportRoutine(params)
        .then(res => {
          this.$ExportFile(res, this.title);
          // const { code, msg } = res;
          // if (code !== 0) {
          //   this.$message({
          //     message: msg || '导出失败',
          //     type: 'error'
          //   });
          // }
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleView(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles';

.form-wrap {
  margin: 0 auto;
  width: 480px;
}
</style>
<style lang="scss">
@import '@/styles';
.dialog {
  .el-dialog__header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 0 0 32px;
    box-sizing: border-box;
    color: #fff;
    background-color: $--color-primary;
  }
  .el-dialog__title {
    font-size: 18px;
  }
  .el-dialog__title,
  .el-dialog__headerbtn,
  .el-dialog__close {
    color: #fff !important;
  }
}
</style>
